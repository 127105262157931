<template>
  <div>
    <q-form class="q-gutter-md q-mt-lg" @submit="onSubmit">
      <q-card-section class="justify-between form-inputs-content">
        <q-select
          filled
          color="indigo-11"
          :options="pix_types"
          v-model="pix_selected"
          label="Tipo de Chave"
          @update:model-value="onChangeSelectedPix(pix_selected.name)"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-select>

        <template :key="key" v-for="(structure, key) in structureForm">
          <template v-if="structure.name === pix_selected.name">
            <q-input
              filled
              clearable
              :debounce="1000"
              :type="structure.type"
              :mask="structure.mask"
              class="q-mb-sm q-mt-lg"
              :dense="$q.platform.is.mobile"
              :maxlength="structure.maxlength"
              :disable="verifyPix(structure.name, structure.maxlength)"
              v-model="currentValue"
              :label="structure.label"
              :rules="[(v) => !!v || $t('validations.required')]"
              lazy-rules="ondemand"
            >
              <template v-slot:prepend>
                <q-icon :name="structure.icon" />
              </template>
            </q-input>
          </template>
        </template>
      </q-card-section>

      <q-card-section class="row q-pt-none">
        <q-btn
          outline
          color="grey"
          type="submit"
          :loading="in_request"
          :disable="in_request"
          :label="$t('login.recover.submit')"
          class="full-width bg-grey-10 q-pa-sm q-pr-lg q-pl-lg"
        />
      </q-card-section>
    </q-form>
  </div>
</template>

<script>
import {
  build,
  labels,
  getStructure,
  getMappedFields,
} from "@/shared/form-presets";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifySuccess } from "@/shared/helpers/notify";
import { useActions } from "vuex-composition-helpers";
import { getFieldsDefinitionsPix } from "./setup";
import { ref, reactive, onMounted } from "vue";
import { getPartnerTypes } from "./setup";

const { useGetters } = createNamespacedHelpers("account");

export default {
  name: "BankAccountTypePix",

  setup() {
    const schema = ref(false),
      in_request = ref(false),
      pix_cpf_cnpj = ref(null),
      currentValue = ref(null),
      form_in_request = ref(true);

    const { pix_account, partner } = useGetters({
        pix_account: "getPartnerPixAccount",
        partner: "getPartnerAccount",
      }),
      { updateUserPartnerPix, validatePix } = useActions({
        updateUserPartnerPix: "account/updateUserPartnerPix",
        validatePix: "account/validatePix",
      });

    const pix_types = getPartnerTypes(partner.value.pf_pj);
    const pix_selected = ref(pix_types[0]);

    const fieldsDefinitions = getFieldsDefinitionsPix(),
      structureForm = reactive(getStructure(Object.keys(fieldsDefinitions)));

    const buildSchema = (selected) => {
      schema.value = build(getMappedFields([selected.name]));
    };

    if (pix_account.value && pix_account.value.type)
      pix_types.forEach((type) => {
        if (type.value === pix_account.value.type) {
          pix_selected.value = type;
          currentValue.value = pix_account.value.content;
        }
      });

    buildSchema(pix_selected.value);

    const verifyPix = (type) => type.includes("cpf") || type.includes("pj");

    const onChangeSelectedPix = (value) => {
      currentValue.value = verifyPix(value)
        ? localStorage.getItem("partnerCPFOrCNPJ")
        : null;
    };

    const onSubmit = () => {
      const { value } = pix_selected.value,
        isPhone = value === "phone",
        isCpfOrCnpj = value === "cpf" || value === "cnpj";

      const content = isPhone
        ? currentValue.value.replace(/[^0-9]/g, "")
        : isCpfOrCnpj
        ? pix_cpf_cnpj.value
        : currentValue.value;

      in_request.value = true;
      updateUserPartnerPix({ type: value, content: content })
        .then(() => {
          notifySuccess("actions.bank_account_changed_success");
        })
        .finally(() => (in_request.value = false));
    };

    onMounted(
      () => (pix_cpf_cnpj.value = localStorage.getItem("partnerCPFOrCNPJ"))
    );

    return {
      partner,
      form_in_request,
      in_request,
      labels,
      schema,
      structureForm,
      pix_cpf_cnpj,
      pix_types,
      pix_selected,
      currentValue,
      verifyPix,
      onSubmit,
      buildSchema,
      validatePix,
      onChangeSelectedPix,
    };
  },
};
</script>
